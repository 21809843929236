import { createAction, props } from '@ngrx/store';

export const configAction = createAction(
    '[setting] config',
    props<{ config: Config }>()
);

export interface Config {
    country: any;
    currency: any;
    timezone: any;
}
